import React from "react";
import './maintenance.scss';
import { FaTwitter, FaInstagram, FaDiscord, FaLinkedinIn } from "react-icons/fa";


function Maintenenance() {
    return (
        <section id="maintenance">
            <h4>Reskue</h4>
            <h1>Maintenance in progress,<br /> we'll be back very soon !</h1>
            {/* <a id="get_notif" href="https://docs.google.com/forms/d/e/1FAIpQLSeTWHGILPvX9K69GdMJ4fohe-bKCioY5S-BIIDfe02-KY8jhA/viewform">
                Get notified
            </a> */}
            <div className="social_media_icons">
                <a target="blank" href="https://twitter.com/ReskueNFT"><FaTwitter className="primary_icon" /></a>
                <a target="blank" href="https://www.instagram.com/reskue.art/"><FaInstagram className="primary_icon" /></a>
                <a target="blank" href="https://discord.com/invite/bsQrnAJqDq"><FaDiscord className="primary_icon" /></a>
                <a target="blank" href="https://www.linkedin.com/company/reskue/"><FaLinkedinIn className="primary_icon" /></a>
            </div>
        </section>
    );
}

export default Maintenenance;