import React, { useRef, useState, useEffect } from 'react';
import Background from './background';
import Maintenenance from './maintenance';

function App() {
  return (
    <div>
      <Maintenenance />
    </div>
  );
}

export default App;
